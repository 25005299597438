import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import { Button, ConnectedOtpInput, Form, ResendCodeButton, SubmitButton, Text, useTheme } from '@almond/ui';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAlmondApiMutation } from '~/modules/api';
import { parseApiResponseError } from '~/modules/errors';
import { useUpdatePatientMutation } from '~/modules/profile/hooks';

import { BaseVerificationModal } from '../BaseVerificationModal';
import { validationSchema } from './validations';

import { themedStyles } from './styles';

import type { BaseVerificationModalProps } from '../BaseVerificationModal';
import type { VerifyPhoneNumberFormValues } from './types';
import type { SmsOptInStatusEnum } from '@almond/api-types';

const OTP_INTERVAL = 60; // 60 seconds

export type VerifyPhoneNumberModalProps = Omit<BaseVerificationModalProps, 'footer'> & {
  onBack?: () => void;
  clearCache: () => void;
  optOutOnRequestClose?: boolean;
  optOutOnError?: boolean;
  optInOnSuccess?: boolean;
  sendTextOnSuccess?: boolean;
};

export const VerifyPhoneNumberModal = (props: VerifyPhoneNumberModalProps) => {
  const {
    patient,
    onBack,
    clearCache,
    onRequestClose,
    optOutOnRequestClose,
    optOutOnError,
    optInOnSuccess,
    sendTextOnSuccess,
    ...restProps
  } = props;
  const [styles] = useTheme(themedStyles);
  const {
    data: otpSession,
    trigger: createOtpSession,
    isMutating: isCreatingOtpSession,
  } = useAlmondApiMutation(
    'post',
    restProps.isVisible ? `/profiles/${patient.profile.uuid as '{profile_uuid}'}/otp_sessions/` : null,
    { revalidate: false }
  );
  const { trigger: validateOtpSession, isMutating: isValidatingOtpSession } = useAlmondApiMutation(
    'post',
    `/profiles/${patient.profile.uuid as '{profile_uuid}'}/otp_sessions/validate/`,
    { revalidate: false }
  );
  const { trigger: sendOptInTextMessage, isMutating: isSendingTextMessage } = useAlmondApiMutation(
    'post',
    // eslint-disable-next-line max-len
    `/patients/${patient.uuid as '{patient_uuid}'}/text_messages/${'opt_in_via_dashboard_message' as '{message_type}'}/`,
    { revalidate: false }
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const { updatePatient, isUpdatingPatient } = useUpdatePatientMutation();

  const updateSmsOptInStatus = useCallback(
    async (smsOptInStatus: SmsOptInStatusEnum) => {
      try {
        await updatePatient({ smsOptInStatus });
        clearCache();
      } catch (error) {
        setErrorMessage(parseApiResponseError(error));
      }
    },
    [clearCache, updatePatient]
  );

  const sendOptInMessage = useCallback(async () => {
    try {
      await sendOptInTextMessage({ patient_uuid: patient.uuid, message_type: 'opt_in_via_dashboard_message' });
    } catch (error) {
      setErrorMessage(parseApiResponseError(error));
    }
  }, [patient.uuid, sendOptInTextMessage]);

  const handleRequestClose = useCallback(async () => {
    if (optOutOnRequestClose) {
      await updateSmsOptInStatus('opted_out');
    }

    onRequestClose();
  }, [onRequestClose, optOutOnRequestClose, updateSmsOptInStatus]);

  const handleSubmit = useCallback(
    async (values: VerifyPhoneNumberFormValues) => {
      try {
        if (!otpSession) {
          return;
        }

        await validateOtpSession({ otp: values.otp, sessionUuid: otpSession.sessionUuid });

        if (optInOnSuccess) {
          await updateSmsOptInStatus('opted_in');
        }

        if (sendTextOnSuccess) {
          await sendOptInMessage();
        }

        clearCache();
        onRequestClose();
      } catch (error) {
        setErrorMessage(parseApiResponseError(error));
        if (optOutOnError) {
          await updateSmsOptInStatus('opted_out');
        }
      }
    },
    [
      clearCache,
      onRequestClose,
      optInOnSuccess,
      optOutOnError,
      otpSession,
      sendOptInMessage,
      sendTextOnSuccess,
      updateSmsOptInStatus,
      validateOtpSession,
    ]
  );

  const createOtpSessionSafely = useCallback(async () => {
    try {
      await createOtpSession({ profile_uuid: patient.profile.uuid });
    } catch (error) {
      setErrorMessage(parseApiResponseError(error));
    }
  }, [createOtpSession, patient.profile.uuid]);

  useEffect(() => {
    if (!restProps.isVisible) {
      setErrorMessage(undefined);

      return;
    }

    createOtpSessionSafely();
  }, [createOtpSessionSafely, restProps.isVisible]);

  const isLoading = isCreatingOtpSession || isValidatingOtpSession || isUpdatingPatient || isSendingTextMessage;

  return (
    <BaseVerificationModal {...restProps} onRequestClose={handleRequestClose} patient={patient}>
      <Form
        name="Verify phone number form"
        onSubmit={handleSubmit}
        resolver={zodResolver(validationSchema)}
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        <View style={styles.contentContainer}>
          <ConnectedOtpInput
            name="otp"
            style={styles.textInput}
            withDefaultStyles={false}
            label="Verify"
            placeholder="6-digit code"
          />
          <ResendCodeButton interval={OTP_INTERVAL} onPress={createOtpSessionSafely} />
        </View>
        {errorMessage && <Text style={styles.error}>{`* ${errorMessage}`}</Text>}
        <View style={styles.footer}>
          {onBack && (
            <Button type="secondary" fixedWidth={false} style={styles.button} onPress={onBack}>
              {'Go Back'}
            </Button>
          )}
          <SubmitButton style={styles.button} fixedWidth={false} requiredFields={['otp']}>
            {'Submit'}
          </SubmitButton>
        </View>
      </Form>
    </BaseVerificationModal>
  );
};
