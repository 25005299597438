import { z } from 'zod';

const apiResponseErrorSchema = z.object({
  info: z.object({
    errors: z
      .object({
        error_code: z.string(),
        message: z.string(),
      })
      .array(),
  }),
});

const defaultError =
  "We've run into an unexpected issue. Please call us at 424-237-8406 and we’ll help you out by phone!";

export const parseApiResponseError = (apiResponseError: unknown): string => {
  const parsedError = apiResponseErrorSchema.safeParse(apiResponseError);

  if (!parsedError.success) {
    return defaultError;
  }

  const filteredErrors = parsedError.data.info.errors.filter(error => error.error_code === 'display_to_user');

  // Display all the errors that should be displayed to the user, otherwise display the default error.
  if (!filteredErrors.length) {
    return defaultError;
  }

  return filteredErrors.map(error => error.message).join('; ');
};
