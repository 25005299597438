import React from 'react';

import { Button, PulseProvider, SkeletonLoader, useTheme } from '@almond/ui';

import { useAlmondApiQuery } from '~/modules/api';
import { useBookUrl, useCurrentUser } from '~/modules/user';
import { Belly, Reproductive } from '~assets';

import { ItemWithAction } from '../ItemWithAction';
import { SectionWithItems } from '../SectionWithItems';

import { themedStyles } from './styles';

export const GetCare: React.FC = () => {
  const currentUser = useCurrentUser();
  const { data, isLoading } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );
  const [styles] = useTheme(themedStyles);
  const generalBookUrl = useBookUrl({ isNewMember: false, category: 'general' });
  const maternityBookUrl = useBookUrl({ isNewMember: false, category: 'maternity' });
  const hasActiveSubscription = ['active', 'trialing'].includes(data?.profile?.subscription?.status || '');
  const loadingText = (() => {
    if (isLoading) return '';

    return hasActiveSubscription ? 'Book' : 'Join';
  })();

  const title = (() => {
    if (isLoading) return <SkeletonLoader.RoundedRectangle width={220} height={16} roundness={4} />;

    return hasActiveSubscription ? 'Get Care' : 'Looking for more in-depth care?';
  })();

  return (
    <PulseProvider duration={1500}>
      <SectionWithItems title={title}>
        <ItemWithAction
          icon={<Reproductive />}
          title="Gynecology Care"
          action={
            <Button size="s" fixedWidth={false} style={styles.button} href={generalBookUrl} isLoading={isLoading}>
              {loadingText}
            </Button>
          }
          separator
        />
        <ItemWithAction
          icon={<Belly />}
          title="Maternity Care"
          action={
            <Button size="s" fixedWidth={false} style={styles.button} href={maternityBookUrl} isLoading={isLoading}>
              {loadingText}
            </Button>
          }
        />
      </SectionWithItems>
    </PulseProvider>
  );
};
