// Array is in order displayed in UI
export const CATEGORIES = [
  { id: 'general', title: 'General' },
  { id: 'follow_ups', title: 'Follow Ups', defaultDueDateDays: 2, dueDateRequired: true },
  { id: 'referrals', title: 'Referrals', defaultDueDateDays: 7, dueDateRequired: true },
  { id: 'imaging', title: 'Imaging' },
  { id: 'labs', title: 'Labs', defaultDueDateDays: 14, dueDateRequired: true },
  { id: 'vaccinations', title: 'Vaccinations', defaultDueDateDays: 14 },
  { id: 'medications_and_supplements', title: 'Medications & Supplements', defaultDueDateDays: 2 },
  { id: 'read', title: 'Read' },
] as const;

export type TodoCategories = (typeof CATEGORIES)[number]['id'];
