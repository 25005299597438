import React, { useMemo } from 'react';
import { View } from 'react-native';

import { ActivityIndicator, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { AdminSidebar, ChannelHeader } from '~/modules/messaging';

import { useAdminSidebar } from '../../hooks';

import { themedStyles } from './styles';

import type { PropsWithChildren } from 'react';

type AdminPatientLayoutProps = PropsWithChildren<{
  showJoinInThread?: boolean;
  hideChildren?: boolean;
}>;

export const AdminPatientLayout = (props: AdminPatientLayoutProps) => {
  const [styles] = useTheme(themedStyles);
  const { channel } = useChatContext();
  const { isMobile, isDesktop } = useBrowserTypeMap();
  const { isSidebarOpen } = useAdminSidebar();

  const content = useMemo(() => {
    // We don`t want to render content until conversationMembers are loaded
    if (!channel || props.hideChildren) {
      return (
        <>
          <ActivityIndicator style={styles.loading} />
          <View style={styles.hide}>{props.children}</View>
        </>
      );
    }

    const conversation = (
      <>
        <ChannelHeader showJoinInThread={props.showJoinInThread} />
        {props.children}
      </>
    );

    if (isMobile) {
      return (
        <View style={styles.channelContainer}>
          <AdminSidebar isOpen={isSidebarOpen} backgroundContent={conversation} />
        </View>
      );
    }

    return <View style={styles.channelContainer}>{conversation}</View>;
  }, [
    channel,
    props.hideChildren,
    props.showJoinInThread,
    props.children,
    styles.channelContainer,
    styles.loading,
    styles.hide,
    isMobile,
    isSidebarOpen,
  ]);

  return (
    <View style={styles.wrapper}>
      {/*
       * Order reversed with flexDirection: row-reverse. So
       * clicking an image brings up the image viewer overlay
       * on top of the sidebar. If we aren't using the default
       * Stream image view overlay, we can switch this to "row"
       * and swap the order of the chat window and sidebar in
       * the DOM.
       */}
      {isDesktop && <AdminSidebar />}
      {content}
    </View>
  );
};
