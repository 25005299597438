import { useEffect, useRef } from 'react';

import { getVisitNoteStatusSchema, WindowMessageSender } from '@almond/extension-utils';
import { uniqueBy, useEvent } from '@almond/utils';

import { useExtensionMessageListener } from './useExtensionMessageListener';

import type { TodoDetailOut } from '@almond/api-types';
import type { SendVisitNoteStatus } from '@almond/extension-utils';
import type { z } from 'zod';

const messageSender = new WindowMessageSender(window, window.parent, null);

export type Todos = { active: readonly TodoDetailOut[]; archived: readonly TodoDetailOut[]; isLoading: boolean };

const useSynchronizeVisitNoteStatus = (todos: Todos, editingVisitNoteId: string | null) => {
  const pendingStatusRequestRef = useRef<string | null>(null);

  const { active, archived, isLoading } = todos;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const toSend = uniqueBy<{ visitNoteId: string | undefined; status: SendVisitNoteStatus['status'] }>(
      // TODO This block may be unnecessary, determine if it is necessary
      [...active, ...archived].map(a => ({ visitNoteId: a.elationVisitNoteId, status: 'published' })),
      'visitNoteId'
    );

    if (editingVisitNoteId) {
      toSend.push({ visitNoteId: editingVisitNoteId, status: 'unpublished-editing' });
    }

    // If a request for a visit note status came in while we were still loading all the todo items,
    // add that visit note to the `toSend` array. If that visit note ID doesn't already exist in `toSend`,
    // that means the status is not published or unpublished-editing, because we didn't see any todo
    // items with that visitNoteId.
    if (pendingStatusRequestRef.current) {
      if (!toSend.find(t => t.visitNoteId === pendingStatusRequestRef.current)) {
        toSend.push({ visitNoteId: pendingStatusRequestRef.current, status: 'unpublished' });
      }

      pendingStatusRequestRef.current = null;
    }

    toSend.forEach(({ visitNoteId, status }) => {
      if (visitNoteId) {
        messageSender.send({
          type: 'send_visit_note_status',
          visitNoteId,
          status,
        } satisfies SendVisitNoteStatus);
      }
    });
  }, [active, archived, editingVisitNoteId, isLoading]);

  const sendVisitNoteStatus = useEvent((message: z.infer<typeof getVisitNoteStatusSchema>) => {
    const { visitNoteId } = message;

    if (isLoading) {
      // Not done loading yet - let the above useEffect handle sending the status
      pendingStatusRequestRef.current = visitNoteId;
    } else {
      let status: SendVisitNoteStatus['status'] = [...active, ...archived].some(
        a => a.elationVisitNoteId === visitNoteId
      )
        ? 'published'
        : 'unpublished';

      if (status === 'unpublished' && editingVisitNoteId === visitNoteId) {
        status = 'unpublished-editing';
      }

      messageSender.send({
        type: 'send_visit_note_status',
        visitNoteId,
        status,
      } satisfies SendVisitNoteStatus);
    }
  });

  useExtensionMessageListener(getVisitNoteStatusSchema, sendVisitNoteStatus);
};

// Disable this entire hook when not running in an iframe
const toExport =
  window.parent === window
    ? ((() => undefined) as typeof useSynchronizeVisitNoteStatus)
    : useSynchronizeVisitNoteStatus;

export { toExport as useSynchronizeVisitNoteStatus };
