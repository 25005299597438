import { REMOTE_DATE_FORMAT } from '@almond/date-and-time';
import dayjs from 'dayjs';
import { z } from 'zod';

import { CATEGORIES } from './config';

export const TITLE_MAX_LENGTH = 95;

export const validationSchema = z
  .object({
    category: z.enum(
      // Some Typescript fiddling to get this to work with z.enum()
      CATEGORIES.map(c => c.id) as [(typeof CATEGORIES)[number]['id'], ...(typeof CATEGORIES)[number]['id'][]]
    ),
    title: z
      .string()
      .max(TITLE_MAX_LENGTH, { message: 'To Do name is too long, fix to publish' })
      .min(1, { message: 'No item name, update item name to publish' }),
    dueDate: z
      .string()
      .refine(date => !date || dayjs(date, REMOTE_DATE_FORMAT, true).isValid(), 'Date is invalid')
      .transform(d => d || undefined)
      .optional(),
    description: z.string(),
  })
  .superRefine((data, ctx) => {
    const { category, dueDate } = data; // Access both category and dueDate

    const categoryObject = CATEGORIES.find(c => c.id === category);

    if (categoryObject && 'dueDateRequired' in categoryObject && categoryObject.dueDateRequired && !dueDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Due date is required for the "${categoryObject.title}" category.`,
        path: ['dueDate'], // Specify the path to the field that has the issue
      });
    }
  });

export const safeParseValidation = (item: unknown) =>
  validationSchema.safeParse(item, {
    errorMap: (issue, ctx) => {
      if (issue.message) {
        return { message: issue.message };
      }

      if (issue.path[0] === 'category') {
        if (issue.code === 'invalid_enum_value') {
          return { message: `"${ctx.data}" is an unknown category, choose a category to publish` };
        }

        if (issue.code === 'invalid_type') {
          return { message: 'No category selected, choose a category to publish' };
        }
      }

      return { message: ctx.defaultError };
    },
  });

export type ValidationErrorType = z.SafeParseError<typeof safeParseValidation>['error'];
