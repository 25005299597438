.mobileSidebar {
  position: absolute;
  z-index: 1;
}

.mobileSidebar:not(.isVisible) {
  display: none;
}

@supports (view-transition-name: sidebar) {
  .mobileSidebar {
    view-transition-name: sidebar;
  }

  ::view-transition-old(sidebar),
  ::view-transition-new(sidebar) {
    animation-duration: 300ms;
    animation-name: slide;
    animation-timing-function: ease-in-out;
  }

  ::view-transition-old(sidebar) {
    animation-direction: reverse;
  }

  @keyframes slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  @media (prefers-reduced-motion) {
    ::view-transition-old(sidebar),
    ::view-transition-new(sidebar) {
      animation-duration: 1ms;
    }
  }
}

.backgroundContent {
  height: 100%;
}
