import {
  loginFailedSchema,
  loginSuccessfulSchema,
  WindowMessageReceiver,
  WindowMessageSender,
} from '@almond/extension-utils';

// Calls the callback if the passed window becomes closed
const onClosed = (window: Window, cb: () => void) => {
  let timeout: NodeJS.Timeout | undefined;

  const runTimeout = () => {
    if (window.closed) {
      cb();
    } else {
      timeout = setTimeout(runTimeout, 200);
    }
  };

  runTimeout();

  return () => {
    clearTimeout(timeout);
  };
};

// We're in an iframe. Auth0 can't open in an iframe,
// so instead open it in a new tab.
export const authenticateForExtension = (url: string) => {
  const authTab = window.open(url, '_blank');

  if (!authTab) {
    return;
  }

  const messageReceiver = new WindowMessageReceiver(window, loginSuccessfulSchema, window.location.href);
  const insecureMessageSender = new WindowMessageSender(window, window.parent, null);
  const cancel = onClosed(authTab, () => {
    // Authentication failed. Tell the parent window (extension.ts)
    // so it can show a login page, instead of going into an infinite login
    // loop.
    messageReceiver.removeListener();
    insecureMessageSender.send(loginFailedSchema.parse({ type: 'login_failed' }));
  });

  messageReceiver.addListener(async () => {
    // Authentication succeeded! Reload the window so react-auth0
    // can "see" the new authenticated state
    cancel();
    messageReceiver.removeListener();
    window.location.reload();
  });
};
