import { forwardRef } from 'react';
import { Pressable, View } from 'react-native';

import { MaterialIcon, Text } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { default as cssStyles, unstable_styles as cssUnstableStyles } from './BaseTodoItem.module.css';
import { TodoItemButton } from './TodoItemButton';

import type { TodoItemButtonProps } from './TodoItemButton';
import type { StyleProp, ViewStyle } from 'react-native';

export { LoadingTodoItem } from './LoadingTodoItem';

export type TodoCtaButton = TodoItemButtonProps | undefined | false | null;

export type BaseTodoItemProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  dueDate?: React.ReactNode;
  id: string;
  leftIcon?: React.ReactNode;
  isExpanded?: boolean;
  onExpand?: (isExpanded: boolean) => void;
  ctaButtons?: TodoCtaButton[];
  style?: StyleProp<ViewStyle>;
  dataSet?: Record<string, string>;
};

export const BaseTodoItem = forwardRef<View, BaseTodoItemProps>((props, ref) => {
  const { title, description, dueDate, id, leftIcon, isExpanded, onExpand, ctaButtons, style, dataSet } = props;

  return (
    <View
      ref={ref}
      style={combineUnstableStyles(cssUnstableStyles.container, style)}
      role="region"
      // dataSet isn't in react-native type definitions, but it's supported in react-native-web.
      // https://github.com/necolas/react-native-web/releases/tag/0.13.0
      // @ts-expect-error
      dataSet={dataSet}
    >
      <View style={combineUnstableStyles(cssUnstableStyles.alwaysVisibleContent, cssUnstableStyles.todoRow)}>
        <View style={cssUnstableStyles.leftIcon}>{leftIcon}</View>
        <View style={cssUnstableStyles.titleContainer}>
          <Text fontStyle="medium" size="m">
            {title}
          </Text>
          {dueDate && (
            <Text size="s" style={cssUnstableStyles.dueDate}>
              {dueDate}
            </Text>
          )}
        </View>
        <Pressable
          style={cssUnstableStyles.expandButton}
          onPress={onExpand && (() => onExpand(!isExpanded))}
          role="button"
          aria-expanded={isExpanded}
          aria-controls={`${id}-details`}
          aria-label={`${isExpanded ? 'Hide' : 'Show'} details`}
          nativeID={`${id}-label`}
        >
          <MaterialIcon
            source="chevron-right"
            style={combineUnstableStyles(
              cssUnstableStyles.caret,
              isExpanded ? cssUnstableStyles.caretExpanded : cssUnstableStyles.caretCollapsed
            )}
            aria-hidden
          />
        </Pressable>
      </View>
      <section
        className={[cssStyles.todoRow, cssStyles.expandedSection, isExpanded ? '' : cssStyles.hidden].join(' ')}
        id={`${id}-details`}
        role={isExpanded ? 'region' : undefined}
        aria-labelledby={`${id}-label`}
      >
        <Text style={cssUnstableStyles.description}>{description}</Text>

        {ctaButtons?.length ? (
          <View style={cssUnstableStyles.buttons}>
            {ctaButtons.map((ctaButton, index) => {
              return ctaButton ? <TodoItemButton key={index} {...ctaButton} /> : null;
            })}
          </View>
        ) : null}
      </section>
    </View>
  );
});
