import dayjs from 'dayjs';
import { titleCase } from 'title-case';
import { v4 } from 'uuid';

import { getClosestCategory } from './getClosestCategory';

export const parseTodoFromElation = <
  ElationTodo extends { text: string },
  Category extends { id: string; title: string; defaultDueDateDays?: number },
>(
  elationTitle: string,
  children: ElationTodo[],
  categories: readonly Category[]
) => {
  // split only on the first colon
  // https://stackoverflow.com/questions/4607745/split-string-only-on-first-instance-of-specified-character
  const [text1, text2] = elationTitle.split(/:(.*)/);

  const title = (text2 || text1).trim();
  const initialCategory = text2 ? text1.trim() : undefined;

  let description = '';

  if (children.length === 1) {
    description = children[0].text.trim();
  } else {
    // Multiple items - turn into bullets.
    // But, if any item has bullets, make those sub-bullets
    // If any item has newlines, turn those into indented newlines
    description = children
      .map(
        item =>
          `- ${item.text
            // Keep all newlines within the same bullet
            .replaceAll('\n', '\n  ')}`
      )
      .join('\n');
  }

  const closestCategory = getClosestCategory(categories, initialCategory);

  return {
    uuid: `pending-${v4()}`,
    isPending: true,
    title: titleCase(title),
    category: closestCategory?.id ?? initialCategory,
    dueDate: closestCategory?.defaultDueDateDays
      ? dayjs().add(closestCategory.defaultDueDateDays, 'days').format('YYYY-MM-DD')
      : undefined,
    initialCategory,
    description,
  } as const;
};
