import { useMemo } from 'react';

import { buildUrlSearchParams, cypressUtilities } from '@almond/utils';
import { useUnstableGlobalHref } from 'expo-router';

import { devEnv, env } from '~env';

type BookUrlParams = {
  isAdmin?: boolean;
  isNewMember?: boolean;
  patientUuid?: string;
  profileUuid?: string;
  product?: string;
  email?: string;
  category?: string;
};

export const useBookUrl = (params: BookUrlParams = {}, pathname?: string) => {
  const unstableGlobalHref = useUnstableGlobalHref();

  return useMemo(() => {
    // Opening a dev URL in Cypress tests in order to continue using the correct env.
    const bookUrl = new URL(cypressUtilities.isCypressRunning() ? devEnv.BOOK_URL : env.BOOK_URL);

    if (pathname) {
      bookUrl.pathname = pathname;
    }

    buildUrlSearchParams(bookUrl.searchParams, params);

    bookUrl.searchParams.append(
      'artemis_redirect_url',
      // Cypress does not support opening a new tab.
      params.isAdmin && !cypressUtilities.isCypressRunning()
        ? '_blank'
        : `${window.location.origin}${unstableGlobalHref}`
    );

    return bookUrl.toString();
  }, [params, pathname, unstableGlobalHref]);
};
