import { useEvent } from '@almond/utils';
import { useSWRConfig } from 'swr';

import { useAlmondApiMutation } from '~/modules/api';

import type { DocumentCreateIn } from '@almond/api-types';

const API_PATH = (patientUuid: string | undefined) =>
  `/patients/${patientUuid as '{patient_uuid}'}/documents/` as const;

export const useAddToDocuments = (patientUuid: string | undefined) => {
  const { trigger } = useAlmondApiMutation('post', API_PATH(patientUuid));
  const { mutate } = useSWRConfig();

  return useEvent(async (attachments: DocumentCreateIn[]) => {
    if (!patientUuid) throw new Error('Patient UUID is not defined');

    if (!attachments.length) return [];

    const result = await Promise.all(attachments.map(attachment => trigger(attachment)));
    // Can only upload 1 category at a time, so the first item's category is guaranteed to be the same as all others
    const { category } = result[0];

    // Add new item(s) to cache so new document is shown in media immediately
    mutate(
      key =>
        Array.isArray(key) &&
        typeof key[0] === 'string' &&
        key[0].startsWith(`${API_PATH(patientUuid)}?category=${category}`),
      data => ({
        documents: [...result, ...(data?.documents ?? [])],
      }),
      { revalidate: false }
    );

    return result;
  });
};
