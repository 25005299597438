import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    ...getShadow(),
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_M,
    padding: sizes.SPACING_L,
  },

  containerWithFooter: {
    paddingBottom: 0,
  },

  title: {
    marginBottom: sizes.SPACING_M,
  },

  footer: {
    borderTopStyle: 'solid',
    borderTopColor: theme.border,
    borderTopWidth: 1,
    marginHorizontal: sizes.SPACING_L * -1,
    marginTop: sizes.SPACING_S,
    paddingVertical: sizes.SPACING_S,
  },
}));
