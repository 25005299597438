import { Slot, useGlobalSearchParams } from 'expo-router';

import { useAlmondApiQuery } from '~/modules/api';
import { ChromeBrowserExtensionCarePlanHandler } from '~/modules/extension';
import { ErrorContainer } from '~/modules/logging';
import { useChannel } from '~/modules/messaging';
import { AdminPatientLayout } from '~/modules/routing';
import { CATEGORIES, useGetTodos } from '~/modules/todos';

import type { ElationDetailsParams } from '~/types';

export { ErrorContainer as ErrorBoundary };

export default () => {
  const { elationId } = useGlobalSearchParams<ElationDetailsParams>();

  const { data, error } = useAlmondApiQuery(
    elationId ? `/patients/by_elation/${elationId as '{elation_patient_id}'}` : null
  );

  const conversationId = data?.authId ? data.authId.replace('auth0|', '') : undefined;

  useChannel(conversationId, !!conversationId);

  const todos = useGetTodos({ patientUuid: data?.uuid });

  if (error) {
    throw error;
  }

  // ChromeBrowserExtensionCarePlanHandler must be outside <AdminPatientLayout>
  // because <AdminPatientLayout> unmounts and remounts children, clearing any
  // state values. The state value in ChromeBrowserExtensionCarePlanHandler must
  // be persisted
  return (
    <ChromeBrowserExtensionCarePlanHandler categories={CATEGORIES} todos={todos}>
      <AdminPatientLayout showJoinInThread>
        <Slot />
      </AdminPatientLayout>
    </ChromeBrowserExtensionCarePlanHandler>
  );
};
