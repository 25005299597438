import { StyleSheet } from 'react-native';

import { getColorWithOpacity, getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  characterCountTextError: {
    color: theme.error,
  },

  bottomMessageContainer: {
    alignItems: 'center',
    backgroundColor: getColorWithOpacity(theme.background, 0.9),
  },

  typingIndicator: {
    color: theme.primary,
  },

  slaMessage: {
    paddingHorizontal: sizes.SPACING_M,
    color: theme.tooltip,
  },
}));

export const directStyles = {
  sendButtonError: {
    backgroundColor: 'var(--color-error)',
    opacity: 0.2,
  },

  quotedMessageContainer: {
    width: '100%',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: 'var(--color-border)',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
  },
  quotedMessageItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  quotedMessageTitle: {
    color: 'var(--color-text)',
    fontWeight: '700',
    fontSize: 11,
  },
  quotedMessageCloseContainer: {
    cursor: 'pointer',
    display: 'flex',
  },
  quotedMessageText: {
    color: 'var(--color-secondary-text)',
    fontWeight: '500',
    fontSize: 11,
    maxWidth: '75%',
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis',
  },
  sendButtonContainer: {
    position: 'relative',
  },
  toolTipContainer: {
    ...(getShadow() as { boxShadow: string }),
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '100%',
    marginRight: sizes.SPACING_XS,
    backgroundColor: 'var(--color-light-secondary-background)',
    borderRadius: sizes.SPACING_S,
    color: 'var(--color-tooltip)',
    fontSize: sizes.FONT_SIZE_S,
    padding: sizes.SPACING_S,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 3,
    whiteSpace: 'nowrap' as const,
    zIndex: 1,
  },
  noCharacterCount: {
    color: 'var(--color-info)',
  },
  characterCountValid: {
    color: 'var(--color-secondary-text-dark)',
  },
  characterCountInvalid: {
    color: 'var(--color-error)',
  },
  toolTipSpanKey: {
    borderRadius: sizes.SPACING_XS,
    backgroundColor: 'var(--color-light-accent)',
    padding: 3,
  },
} as const;
