import React from 'react';

import { Form, useBrowserTypeMap, useTheme } from '@almond/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';

import { Modal } from '~/modules/ui';

import { DemographicForm } from './DemographicForm';
import { useUpdatePatient } from './useUpdatePatient';
import { validationSchema } from './validations';

import { themedStyles } from './styles';

import type { DefaultDemographicFormValues } from './types';
import type { PatientDetailOut } from '@almond/api-types';
import type { ModalProps } from '~/modules/ui';

export type EditDemographicModalProps = Omit<ModalProps, 'title' | 'subtitle' | 'footer'> & {
  patient: PatientDetailOut;
  clearCache: () => void;
  isAdmin?: boolean;
  patientUuid: string;
  onPhoneChange?: () => void;
};

export const EditDemographicModal = (props: EditDemographicModalProps) => {
  const { patient, clearCache, onPhoneChange, isAdmin, patientUuid, ...restProps } = props;
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();
  const defaultValues: DefaultDemographicFormValues = {
    firstName: patient.profile.firstName,
    preferredFirstName: patient.profile.preferredFirstName,
    lastName: patient.profile.lastName,
    birthday: dayjs(patient.profile.birthday, ['YYYY-MM-DD']).format('MM/DD/YYYY'),
    email: patient.profile.email,
    phone: patient.profile.phone,
    isOptedIntoSms: patient.profile.smsOptInStatus === 'opted_in' && patient.profile.isSmsVerified,
  };
  const { onSubmit, isMutating, errorMessage } = useUpdatePatient(
    patientUuid,
    defaultValues,
    isAdmin,
    clearCache,
    restProps.onRequestClose,
    onPhoneChange
  );

  const subtitle = isAdmin
    ? // eslint-disable-next-line max-len
      "You may alter any field on the patient except the phone number, because that would alter the user's SMS opt in status."
    : // eslint-disable-next-line max-len
      'If you receive SMS messages from Almond changing your cell phone number means this new number will receive messages.';

  return (
    <Modal
      {...restProps}
      title="Edit Personal Info"
      subtitle={subtitle}
      containerStyle={[styles.modalContainer, isMobile && styles.modalContainerMobile]}
      contentContainerStyle={styles.modalContentContainer}
      testID="EditPersonalInfoModal"
    >
      <Form
        name="Edit demographic form"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        resolver={zodResolver(validationSchema)}
        isLoading={isMutating}
        isDisabled={isMutating}
      >
        <DemographicForm defaultValues={defaultValues} errorMessage={errorMessage} isAdmin={isAdmin} />
      </Form>
    </Modal>
  );
};
