import { View } from 'react-native';

import { sizes, SkeletonLoader } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { unstable_styles as cssUnstableStyles } from './BaseTodoItem.module.css';

export const LoadingTodoItemLite = () => {
  return (
    <View style={cssUnstableStyles.containerLite}>
      <View style={combineUnstableStyles(cssUnstableStyles.alwaysVisibleContent, cssUnstableStyles.todoRow)}>
        <SkeletonLoader.RoundedRectangle
          roundness={sizes.SPACING_XS}
          height={20}
          width={20}
          style={cssUnstableStyles.leftIcon}
        />
        <SkeletonLoader.RoundedRectangle
          roundness={sizes.SPACING_XS}
          height={17}
          width={150}
          style={cssUnstableStyles.titleContainer}
        />
      </View>
    </View>
  );
};
