import React from 'react';
import { FlatList, View } from 'react-native';

import { ActivityIndicator, Button, sizes, Text, useTheme } from '@almond/ui';
import { cypressUtilities, useClipboard } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import { Error, SidebarButton } from '~/modules/ui';
import { getChannelPatient, useCurrentUser } from '~/modules/user';

import { formatBirthday, formatPhone, formatSmsStatus, formatSubscriptionStatus } from '../../utils';

import { themedStyles } from './styles';

import type { PatientDetailOut } from '@almond/api-types';

export type PatientInformationProps = {
  canViewElation?: boolean;
  data?: PatientDetailOut;
  error?: any;
  separateName?: boolean;
};

export const PatientInformation: React.FC<PatientInformationProps> = props => {
  const { data, error, separateName } = props;
  const { channel } = useChatContext();
  const patientUuid = getChannelPatient(channel)?.patient_uuid;
  const [styles] = useTheme(themedStyles);
  const isPatient = !!useCurrentUser()?.patientUuid;
  const { copy, isCopied } = useClipboard();

  if (channel && !patientUuid) {
    return <Error error="Patient ID not found. Please contact engineering for assistance." />;
  }

  if (error) return <Error error="Error loading patient information. Please refresh the page." />;

  const fields = [
    separateName && { label: 'First name', value: data?.profile.firstName },
    separateName && { label: 'Last name', value: data?.profile.lastName },
    !separateName && {
      label: 'Legal name',
      value: data ? (
        `${data.profile.firstName} ${data.profile.lastName}`
      ) : (
        <ActivityIndicator size={sizes.SPACING_M} lineWidth={sizes.SPACING_XXS} style={styles.loading} />
      ),
    },
    data?.profile.preferredFirstName && { label: 'Preferred name', value: data.profile.preferredFirstName },
    { label: 'DOB', value: data && formatBirthday(data?.profile.birthday) },
    {
      label: 'Email',
      value: data && (
        <>
          {!isPatient && (
            <Button
              style={styles.copyButton}
              labelStyle={styles.copyButtonLabel}
              onPress={() => copy(data.profile.email)}
            >
              {isCopied ? 'Email copied!' : 'Copy email'}
            </Button>
          )}
          <Text style={styles.text} numberOfLines={1}>
            {data.profile.email}
          </Text>
        </>
      ),
    },
    { label: 'Cell', value: data && formatPhone(data.profile.phone) },
  ];

  if (!isPatient) {
    fields.push({ label: 'Membership', value: data && formatSubscriptionStatus(data.profile.subscriptionStatus) });
  }

  if (!isPatient) {
    fields.push({
      label: 'SMS Status',
      value: data && formatSmsStatus(data.profile.smsOptInStatus, data.profile.isSmsVerified),
    });

    const tagsContainer = data?.tags?.length ? (
      <FlatList
        style={styles.tagsContainer}
        data={data?.tags}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item }) => (
          <Text style={styles.bulletItem}>
            <Text style={styles.bullet}>•</Text>
            <Text>{item}</Text>
          </Text>
        )}
      />
    ) : (
      <Text>{data ? 'None' : ''}</Text>
    );

    fields.push({
      label: 'Elation Tags',
      value: tagsContainer,
    });
  }

  return (
    <>
      {fields.map(
        d =>
          d && (
            <View key={d.label} style={styles.textField}>
              <Text fontStyle="bold" style={styles.text}>
                {d.label}:
              </Text>
              {typeof d.value === 'string' ? <Text style={styles.text}>{d.value}</Text> : d.value}
            </View>
          )
      )}
      {props.canViewElation && (
        <SidebarButton
          isDisabled={!data}
          href={data?.elationChart}
          // Cypress does not support opening a new tab.
          hrefAttrs={cypressUtilities.isCypressRunning() ? undefined : { target: '_blank' }}
          style={styles.elation}
        >
          View Elation Member Chart
        </SidebarButton>
      )}
    </>
  );
};
