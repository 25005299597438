/* eslint-disable max-statements */
import { useRef, useState } from 'react';

import { useEvent } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiMutation } from '~/modules/api';
import { parseApiResponseError } from '~/modules/errors';
import { getChannelPatient, useCurrentUser } from '~/modules/user';

import type { ParsedVisitNote } from '../types';
import type { TodoCategoryEnum, TodoDetailOut } from '@almond/api-types';

const wrapError = (cause: unknown) => {
  const message = [
    'There was an error sending the message to the member.',
    'THE TO DO ITEMS ABOVE WERE ALREDAY SUBMITTED, PLEASE DO NOT EDIT THEM FURTHER ON THIS PAGE',
  ].join('\n');
  const newError = new Error(message);

  newError.cause = cause;
  (newError as any).info = {
    errors: [
      {
        error_code: 'display_to_user',
        message,
      },
    ],
  };

  return newError;
};

export const useSubmitBulkTodos = () => {
  const { channel } = useChatContext();
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | undefined;
  const currentUser = useCurrentUser();
  const isChannelMember = Boolean(currentUser && channel?.state.members[currentUser?.streamId]);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const alreadySentRef = useRef({
    todos: null as null | readonly TodoDetailOut[],
    message: false,
    addAndRemoveMember: null as null | boolean,
  });

  const { trigger, isMutating: isSubmitting } = useAlmondApiMutation('post', `/patients/{patient_uuid}/todos/batch/`);

  const sendAndJoin = useEvent(async (message: string, addAndRemoveMember: boolean) => {
    try {
      // Even if message is falsy, we still need to remove member, because they may
      // have been added and not removed in a previous call. So check for falsy
      // message inside the `try` block
      if (message) {
        if (addAndRemoveMember && currentUser) {
          await channel?.addMembers([currentUser.streamId]);
          // await channel?.addMembers([ currentUser?.streamId])
        }

        await channel?.sendMessage({ text: message });
      }
    } catch (e) {
      // If the Stream request fails, add a hardcoded error message
      // to show the user
      throw wrapError(e);
    } finally {
      if (addAndRemoveMember && currentUser) {
        await channel?.removeMembers([currentUser.streamId]);
      }
    }
  });

  // This is idempotent (as long as you await the first call before making the second
  // call). A second call won't cause either todo creation or message sending to
  // happen a second (identical) time.
  const submitBulkTodos = useEvent(async (parsedVisitNote: ParsedVisitNote, visitNoteId: string | null) => {
    if (!patientUuid) {
      return setSubmitError('Missing patient ID');
    }

    if (!visitNoteId) {
      return setSubmitError('Missing Visit Note ID');
    }

    if (alreadySentRef.current.addAndRemoveMember == null) {
      alreadySentRef.current.addAndRemoveMember = !isChannelMember;
    }

    try {
      if (!alreadySentRef.current.todos) {
        const todos = parsedVisitNote.todos.map(pendingTodo => {
          const { title, description, category, dueDate } = pendingTodo;

          return {
            title,
            description,
            category: category as TodoCategoryEnum,
            dueDate,
            elationVisitNoteId: visitNoteId,
          };
        });

        const result = await trigger({ patient_uuid: patientUuid, todos });

        alreadySentRef.current.todos = result.todos;
      }

      if (!alreadySentRef.current.message) {
        await sendAndJoin(parsedVisitNote.message.trim(), alreadySentRef.current.addAndRemoveMember);
        alreadySentRef.current.message = true;
      }

      return alreadySentRef.current.todos;
    } catch (e) {
      setSubmitError(parseApiResponseError(e));
    }
  });

  return {
    submitBulkTodos,
    isSubmitting,
    submitError,
  };
};
