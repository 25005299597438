import { forwardRef } from 'react';
import { View } from 'react-native';

import { MaterialIcon, Text } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';
import { Link } from 'expo-router';

import { unstable_styles as cssUnstableStyles } from './BaseTodoItem.module.css';

import type { BaseTodoItemProps } from './BaseTodoItem';

export { LoadingTodoItemLite } from './LoadingTodoItemLite';

export const BaseTodoItemLite = forwardRef<
  View,
  Omit<BaseTodoItemProps, 'onExpand' | 'isExpanded' | 'ctaButtons' | 'description'>
>((props, ref) => {
  const { title, dueDate, leftIcon, style, dataSet, id } = props;

  return (
    <View
      ref={ref}
      style={combineUnstableStyles(cssUnstableStyles.containerLite, style)}
      role="region"
      // dataSet isn't in react-native type definitions, but it's supported in react-native-web.
      // https://github.com/necolas/react-native-web/releases/tag/0.13.0
      // @ts-expect-error
      dataSet={dataSet}
    >
      <View style={combineUnstableStyles(cssUnstableStyles.alwaysVisibleContent, cssUnstableStyles.todoRow)}>
        <View style={cssUnstableStyles.leftIcon}>{leftIcon}</View>
        <View style={cssUnstableStyles.titleContainer}>
          <Text fontStyle="medium" size="m">
            {title}
          </Text>
          {dueDate && (
            <Text size="s" style={cssUnstableStyles.dueDate}>
              {dueDate}
            </Text>
          )}
        </View>
        <Link
          href={{
            pathname: '/(patient)/todos/',
            params: { selected: id },
          }}
        >
          <MaterialIcon source="chevron-right" style={combineUnstableStyles(cssUnstableStyles.caret)} aria-hidden />
        </Link>
      </View>
    </View>
  );
});
