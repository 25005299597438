import React, { useLayoutEffect, useState } from 'react';
import { View } from 'react-native';

import { ScrollView, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';
import { flushSync } from 'react-dom';

import { unstable_styles as cssUnstableStyles } from './Sidebar.module.css';

import { themedStyles } from './styles';

export type SidebarProps = React.PropsWithChildren<{
  footer?: React.ReactNode;
  isOpen?: boolean;
  backgroundContent?: React.JSX.Element;
}>;

export const Sidebar: React.FC<SidebarProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { isMobile, isDesktop } = useBrowserTypeMap();

  const [isVisible, setIsVisible] = useState(props.isOpen ?? true);

  useLayoutEffect(() => {
    if ('startViewTransition' in document) {
      // Animate the opening/closing of the side panel
      document.startViewTransition(() => {
        flushSync(() => {
          setIsVisible(props.isOpen ?? true);
        });
      });
    } else {
      // Firefox/old browsers don't support view transition
      setIsVisible(props.isOpen ?? true);
    }
  }, [props.isOpen]);

  return (
    <>
      <View
        style={combineUnstableStyles(
          styles.container,
          isMobile && styles.containerMobile,
          isMobile && cssUnstableStyles.mobileSidebar,
          isMobile && isVisible && cssUnstableStyles.isVisible
        )}
        testID="Sidebar"
      >
        {isDesktop && (
          <View style={styles.header}>
            <Text fontStyle="bold" size="m" accessibilityLevel={3} style={styles.text}>
              Info
            </Text>
          </View>
        )}
        <ScrollView style={styles.contents} listenToNativeEvents>
          {props.children}
        </ScrollView>
        {props.footer && <View style={styles.footer}>{props.footer}</View>}
      </View>
      {props.backgroundContent && <View style={cssUnstableStyles.backgroundContent}>{props.backgroundContent}</View>}
    </>
  );
};
