import React, { useState } from 'react';
import { View } from 'react-native';

import { ActivityIndicator, sizes, Text, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { Error, Placeholder, SidebarButton } from '~/modules/ui';
import { getChannelPatient } from '~/modules/user';

import { PatientVisitPreviewItem } from './PatientVisitPreviewItem';
import { PatientVisitsModal } from './PatientVisitsModal';

import { themedStyles } from './styles';

export type PatientVisitsProps = {
  revalidateOnFocus?: boolean;
};

export const PatientVisits: React.FC<PatientVisitsProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { revalidateOnFocus } = props;
  const { channel } = useChatContext();
  const patientUuid = getChannelPatient(channel)?.patient_uuid;
  const { data, error, isLoading } = useAlmondApiQuery(
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/appointments/future_appointments` : null,
    {
      revalidateOnFocus,
    }
  );
  const [isVisible, setIsVisible] = useState(false);

  if (isLoading) return <ActivityIndicator size={sizes.SPACING_M} />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const handlePress = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <View testID="PatientVisits">
      <View style={styles.previewItems}>
        <Text style={styles.upcoming} fontStyle="bold">
          {'Upcoming'}
        </Text>
        {!data.appointments.length && <Placeholder>{'No upcoming visits.'}</Placeholder>}
        {data.appointments.slice(0, 2).map(appointment => (
          <PatientVisitPreviewItem key={appointment.rebookingUrl} item={appointment} />
        ))}
      </View>
      <View style={styles.buttons}>
        {data.appointments.length > 2 && (
          <>
            <SidebarButton onPress={handlePress}>{'View All Visits'}</SidebarButton>
            <PatientVisitsModal isVisible={isVisible} onRequestClose={handleClose} items={data.appointments} />
          </>
        )}
      </View>
    </View>
  );
};
