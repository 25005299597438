import { useCallback } from 'react';

import { useGlobalSearchParams, useRouter } from 'expo-router';

export const useExpandedTodoInUrl = () => {
  const router = useRouter();
  const params = useGlobalSearchParams();
  const expandedId = (params.selected as string | undefined) ?? null;
  const setExpandedId = useCallback(
    (uuid: string | null) => {
      router.setParams<{ selected: string | undefined }>({ selected: uuid ?? undefined });
    },
    [router]
  );

  return { expandedId, setExpandedId };
};
