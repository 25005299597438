import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserTypeMap } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';
import { useSegments } from 'expo-router';

import { unstable_styles as cssStyles } from './Header.module.css';

export type HeaderProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  leftAlignTitle?: boolean;
  title?: string;
  isSidebarOpen?: boolean;
};

/* eslint-disable @typescript-eslint/naming-convention */
const titles: Record<string, string> = {
  messages: 'You & Care Team',
  '[conversationId]': 'Messages',
};
/* eslint-enable @typescript-eslint/naming-convention */

export const Header: React.FC<HeaderProps> = props => {
  const segments = useSegments();
  const { isMobile } = useBrowserTypeMap();

  const currentPage =
    props.title ??
    (() => {
      if (props.isSidebarOpen) return 'More Info';

      const slug = segments[1] || '';

      if (slug in titles && isMobile) return titles[slug];

      return undefined;
    })();

  const showCentered = !props.leftAlignTitle || !!props.left;

  const titleEle = (
    <Text
      fontStyle="bold"
      size={currentPage ? 'xl' : 'xxl'}
      style={combineUnstableStyles(cssStyles.title, showCentered && cssStyles.centerText)}
    >
      {currentPage || 'almond'}
    </Text>
  );

  return (
    <View style={cssStyles.header} testID="ArtemisHeader">
      <View style={cssStyles.left}>{showCentered ? props.left : titleEle}</View>
      <View style={cssStyles.center}>{showCentered && titleEle}</View>
      <View style={cssStyles.right}>{props.right}</View>
    </View>
  );
};
