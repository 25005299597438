import { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import { WindowMessageSender } from '@almond/extension-utils';
import { Button, Text, TextInput } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';
import { v4 } from 'uuid';

import { useChromeBrowserExtensionCarePlanVisitNoteContext } from '~/modules/extension';
import { Error as ErrorUi } from '~/modules/ui';
import { getChannelPatient } from '~/modules/user';

import { useGetTodos, useSubmitBulkTodos } from '../../hooks';
import { safeParseValidation } from '../../validations';
import { DraftTodoItem } from '../DraftTodoItem';
import { LiveTodoItem } from '../LiveTodoItem';
import { TodoFormModal } from '../TodoFormModal';
import { LoadingTodoList, TodoList } from '../TodoList';
import { unstable_styles as cssUnstableStyles } from './BulkTodoPage.module.css';

import type { PendingTodoItem } from '../../types';
import type { SendVisitNoteStatus } from '@almond/extension-utils';

export const BulkTodoPage = () => {
  const { channel } = useChatContext();
  const patient = getChannelPatient(channel);
  const patientUuid = patient?.patient_uuid as string | undefined;
  const preferredName = patient?.preferred_first_name || patient?.first_name;
  const { active, archived, error, isLoading, modifyCache } = useGetTodos({ patientUuid });
  const [formModel, setFormModel] = useState<Partial<PendingTodoItem> | null>(null);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  const { submitBulkTodos, isSubmitting, submitError } = useSubmitBulkTodos();
  const {
    parsedVisitNote,
    setPendingTodos,
    setPendingMessage,
    clearPendingState,
    visitNoteId,
    isLoading: isLoadingVisitNote,
    error: pendingError,
  } = useChromeBrowserExtensionCarePlanVisitNoteContext();

  const listOfTodos = useMemo(() => [...(active ?? []), ...parsedVisitNote.todos], [active, parsedVisitNote.todos]);
  const pendingTodoValidationErrors = useMemo(
    () =>
      Object.fromEntries(
        parsedVisitNote.todos
          .map(todo => {
            const validation = safeParseValidation(todo);

            return validation.success ? undefined : ([todo.uuid, validation.error] as const);
          })
          .filter(<T,>(entry: T | undefined): entry is T => !!entry)
      ),
    [parsedVisitNote.todos]
  );
  const isSubmitDisabled = Object.keys(pendingTodoValidationErrors).length > 0;

  const onSubmit = useCallback(async () => {
    const resultTodos = await submitBulkTodos(parsedVisitNote, visitNoteId);

    if (resultTodos && visitNoteId) {
      resultTodos.forEach(todo => modifyCache('create', todo));
      clearPendingState();
      if (window !== window.parent) {
        const messageSender = new WindowMessageSender(window, window.parent, null);

        await messageSender.send({
          type: 'send_visit_note_status',
          visitNoteId,
          status: 'published',
        } satisfies SendVisitNoteStatus);
      }
    }
  }, [clearPendingState, modifyCache, parsedVisitNote, submitBulkTodos, visitNoteId]);

  if (error || pendingError) {
    return (
      <ErrorUi
        error={
          new Error(
            // eslint-disable-next-line max-len
            `There was an error fetching the to do list. Let us know in #bugs`,
            { cause: error || pendingError }
          )
        }
      />
    );
  }

  if (isLoading || isLoadingVisitNote) {
    return <LoadingTodoList />;
  }

  return (
    <>
      <TextInput
        placeholder={[
          `Type a message to ${preferredName}.`,
          `When you press the Publish button, the message will be sent to the member.`,
        ].join(' ')}
        value={parsedVisitNote.message}
        onChangeText={val => setPendingMessage(val)}
        multiline
        containerStyle={cssUnstableStyles.messageInputContainer}
        style={cssUnstableStyles.messageInput}
      />
      <TodoList
        todos={listOfTodos}
        archivedTodos={archived}
        showAllCategories
        onClickAddTodo={category => {
          setFormModel({ category, initialCategory: category });
        }}
        renderItem={({ item }) => {
          const onExpand = (isExpanded: boolean) => {
            if (isExpanded) {
              setExpandedItems(e => ({ ...e, [item.uuid]: true }));
            } else {
              setExpandedItems(e => ({ ...e, [item.uuid]: false }));
            }
          };

          if ('isPending' in item) {
            return (
              <DraftTodoItem
                item={item}
                isExpanded={expandedItems[item.uuid]}
                onExpand={onExpand}
                errors={pendingTodoValidationErrors[item.uuid]}
                ctaButtons={[
                  {
                    onPress: () => setFormModel(item),
                    label: 'Edit',
                  },
                  {
                    onPress: () => {
                      setPendingTodos(prevVal => prevVal.filter(i => i.uuid !== item.uuid));
                    },
                    label: 'Delete',
                  },
                ]}
              />
            );
          }

          // TODO queue up edits to live todo items
          return <LiveTodoItem item={item} isExpanded={expandedItems[item.uuid]} onExpand={onExpand} />;
        }}
      />
      <TodoFormModal
        isVisible={!!formModel}
        onRequestClose={() => setFormModel(null)}
        model={formModel}
        onSubmit={async values => {
          if (!formModel) {
            return;
          }

          const newItem: PendingTodoItem = {
            ...formModel,
            ...values,
            initialCategory: undefined,
            isPending: true as const,
            uuid: formModel?.uuid ?? `pending-${v4()}`,
          };

          setPendingTodos(prevVal =>
            formModel?.uuid
              ? prevVal.map(p => (p.uuid === formModel.uuid ? newItem : p))
              : [...(prevVal ?? []), newItem]
          );

          return newItem;
        }}
      />
      <View style={cssUnstableStyles.submitButtonContainer}>
        <Button
          size="s"
          type="accent"
          isDisabled={isSubmitDisabled || isSubmitting || parsedVisitNote.todos.length === 0}
          onPress={onSubmit}
          style={cssUnstableStyles.submitButton}
        >
          Publish To Dos
        </Button>
        {(isSubmitDisabled || submitError) && (
          <Text style={cssUnstableStyles.error}>{submitError ?? 'Please fix errors above'}</Text>
        )}
      </View>
    </>
  );
};
