import React, { useMemo } from 'react';
import { View } from 'react-native';

import {
  PulseProvider,
  ScrollView,
  sizes,
  SkeletonLoader,
  Text,
  useAdaptive,
  useBrowserTypeMap,
  useTheme,
} from '@almond/ui';
import { useToggle } from '@almond/utils';

import { useAlmondApiQuery } from '~/modules/api';
import { PatientInformation } from '~/modules/messaging';
import { Membership } from '~/modules/patient';
import { EditDemographicModal, EditRightAction, VerifyPhoneNumberModal } from '~/modules/profile';
import { PatientPageTitle } from '~/modules/routing';
import { BackgroundImage, Section } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { themedStyles } from './styles';

const SettingsPage = () => {
  const [styles] = useTheme(themedStyles);
  const titleSize = useAdaptive('xxxxl', 'xxxl');
  const currentUser = useCurrentUser();
  const { data, isLoading, error, mutate } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );
  const { isDesktop } = useBrowserTypeMap();
  const [isEditDemographicModalVisible, toggleIsEditDemographicModalVisible] = useToggle(false);
  const [isVerifyPhoneNumberModalVisible, toggleVerifyPhoneNumberModalVisible] = useToggle(false);
  const content = useMemo(() => {
    if (isLoading) {
      return (
        <>
          <SkeletonLoader.RoundedRectangle width="100%" height={222} roundness={sizes.SPACING_S} />
          <SkeletonLoader.RoundedRectangle width="100%" height={108} roundness={sizes.SPACING_S} />
        </>
      );
    }

    const isOptedIn = data?.profile.smsOptInStatus === 'opted_in' && data.profile.isSmsVerified;

    return (
      <>
        <Section
          title="Personal Information"
          rightAction={<EditRightAction onPress={toggleIsEditDemographicModalVisible} />}
          testID="PersonalInfo"
        >
          <PatientInformation data={data} error={error} separateName />
        </Section>
        <Section
          title="SMS Settings"
          rightAction={<EditRightAction onPress={toggleIsEditDemographicModalVisible} />}
          testID="SmsSettings"
        >
          <Text testID={isOptedIn ? 'OptedInMessage' : 'OptedOutMessage'}>
            {isOptedIn
              ? // eslint-disable-next-line max-len
                'You currently can receive and send text messages directly with your Care Team about clinical and billing information.'
              : 'You are currently not opted into receiving text messages from your Care Team.'}
          </Text>
        </Section>
        <Membership data={data} />
      </>
    );
  }, [data, error, isLoading, toggleIsEditDemographicModalVisible]);

  return (
    <PulseProvider duration={1000}>
      <View style={[styles.container, isDesktop && styles.containerDesktop]}>
        <BackgroundImage type="desktop" />
        <PatientPageTitle title="Account Settings" />
        <Text size={titleSize} fontStyle="bold" family="PlayfairDisplay" style={styles.title}>
          {'Account Settings'}
        </Text>

        <ScrollView contentContainerStyle={styles.contentContainer}>
          <View style={[styles.content, isDesktop && styles.contentDesktop]}>{content}</View>
        </ScrollView>
      </View>
      {data && currentUser?.patientUuid && (
        <EditDemographicModal
          isVisible={isEditDemographicModalVisible}
          onRequestClose={toggleIsEditDemographicModalVisible}
          showClose
          patient={data}
          clearCache={() => mutate()}
          onPhoneChange={toggleVerifyPhoneNumberModalVisible}
          patientUuid={currentUser.patientUuid}
        />
      )}
      {data && (
        <VerifyPhoneNumberModal
          isVisible={isVerifyPhoneNumberModalVisible}
          onRequestClose={toggleVerifyPhoneNumberModalVisible}
          title="Verify Cell Phone Number"
          subtitle={
            // eslint-disable-next-line max-len
            'If you receive SMS messages from Almond, changing your cell phone number means this new number will receive messages.'
          }
          patient={data}
          onBack={() => {
            toggleVerifyPhoneNumberModalVisible();
            toggleIsEditDemographicModalVisible();
          }}
          sendTextOnSuccess
          clearCache={() => mutate()}
        />
      )}
    </PulseProvider>
  );
};

export default SettingsPage;
