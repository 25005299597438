import { useCallback, useEffect } from 'react';

import { useDeferred } from '@almond/utils';
import { MessageInput, useChannelActionContext, useChatContext } from 'stream-chat-react';

import { DraftAttachmentProvider, getFileType, useDraftAttachmentContext } from '~/modules/documents';
import { ChromeBrowserExtensionUploader } from '~/modules/extension';
import { logErrorToSentry } from '~/modules/logging';
import { getChannelPatient } from '~/modules/user';

import { MessageInputFlatWrapper } from './MessageInputFlatWrapper';
import { TypingIndicator } from './TypingIndicator';

import type { CustomStreamAttachment, DraftAttachment } from '~/modules/documents/types';
import type { MessageInputProps } from 'stream-chat-react';

export const AlmondMessageInput: typeof MessageInput = props => {
  const { attachments, clearOnMessageSend } = useDraftAttachmentContext();
  const { sendMessage } = useChannelActionContext();
  const { client, channel } = useChatContext();
  const attachmentsDeferred = useDeferred<DraftAttachment[]>([attachments]);

  const isAdmin = client?.user?.role === 'provider' || client?.user?.role === 'careguide';

  useEffect(() => {
    if (attachments.every(attachment => attachment.documentUuid)) {
      attachmentsDeferred.resolve(attachments);
    }
  }, [attachments, attachmentsDeferred]);

  const overrideSubmitHandler = useCallback(
    async (message: any) => {
      const attachmentsResolved = await attachmentsDeferred.promise;

      try {
        await sendMessage({
          ...message,

          // This adds attachments to the message
          attachments: attachmentsResolved
            ?.filter(f => f.cdn?.url)
            .map((f): CustomStreamAttachment => {
              const fileName = f.cdn?.fileName ?? ('file' in f ? f.file.name : 'File name missing');

              if (!f.documentUuid) {
                logErrorToSentry('Missing documentUuid in document', f.cdn);
              }

              if (getFileType(fileName) === 'image') {
                return {
                  type: 'image',
                  fallback: fileName,
                  image_url: f.cdn?.url,
                  almondDocumentId: f.documentUuid,
                  isAlmondHealthRecord: f.isHealthRecord,
                };
              }

              return {
                type: 'file',
                asset_url: f.cdn?.url,
                title: fileName,
                almondDocumentId: f.documentUuid,
                isAlmondHealthRecord: f.isHealthRecord,
              };
            }),
        });
      } catch (e) {
        if (e && typeof e === 'object' && 'message' in e) {
          e.message = `Stream message failed to send (${e.message})`;
        }

        logErrorToSentry(e, { attachmentDocumentIds: attachmentsResolved?.map(a => a.id).join(',') || '' });
        // eslint-disable-next-line no-alert
        window.alert(
          // eslint-disable-next-line max-len
          'The message was unable to send. Please try again. If the message still will not send, copy+paste your message somewhere else (a "Notes" app for example), refresh this page, and try again.'
        );
      }

      clearOnMessageSend();
    },
    [attachmentsDeferred.promise, clearOnMessageSend, sendMessage]
  );

  return (
    <>
      <TypingIndicator />
      <MessageInput
        {...props}
        grow={true}
        Input={MessageInputFlatWrapper}
        additionalTextareaProps={
          {
            placeholder:
              isAdmin && channel?.data
                ? `You're messaging ${channel.data.first_name} ${channel.data.last_name} (${channel.data.birthday})`
                : 'Type a message…',
            enterKeyHint: 'send',
          } as any
        }
        overrideSubmitHandler={overrideSubmitHandler}
        disableMentions
        shouldSubmit={isAdmin ? event => event.key === 'Enter' && (event.metaKey || event.ctrlKey) : undefined}
      />
    </>
  );
};

const Wrapped = (props: MessageInputProps) => {
  const { channel } = useChatContext();
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string;

  return (
    <DraftAttachmentProvider patientUuid={patientUuid}>
      <ChromeBrowserExtensionUploader />
      <AlmondMessageInput {...props} />
    </DraftAttachmentProvider>
  );
};

export { Wrapped as MessageInput };
