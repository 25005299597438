.submitButtonContainer {
  position: sticky;
  bottom: 0;

  width: calc(100% + calc(2 * 8px));
  background-color: var(--color-light-secondary-background);
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px;
  gap: 8px;
}

.submitButton {
  max-width: 100%;
}

.error {
  color: var(--color-error);
}
.error::before {
  content: '* ';
}

.messageInputContainer {
  width: 100%;
}

.messageInput {
  min-height: 100px;
}
