import React from 'react';

import { Header, useSidebar } from '~/modules/ui';

import { Left } from './Left';
import { Right } from './Right';

export const AdminHeader: React.FC = () => {
  const { isSidebarDisplayed } = useSidebar();

  return <Header left={<Left />} right={<Right />} isSidebarOpen={isSidebarDisplayed} />;
};
