import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type SectionWithItemsProps = React.PropsWithChildren<{
  title: React.ReactNode;
  footer?: React.ReactNode;
}>;

export const SectionWithItems: React.FC<SectionWithItemsProps> = props => {
  const { children, title, footer } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.container, footer ? styles.containerWithFooter : null]}>
      {typeof title === 'string' ? (
        <Text fontStyle="bold" size="xl" style={styles.title}>
          {title}
        </Text>
      ) : (
        title
      )}
      {children}
      {footer && <View style={styles.footer}>{footer}</View>}
    </View>
  );
};
