import { View } from 'react-native';

import { Text } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { ErrorIcon } from './ErrorIcon';
import { unstable_styles as cssUnstableStyles } from './ValidationMessage.module.css';

import type { ReactNode } from 'react';

type ValidationMessageProps = {
  type: 'info' | 'error';
  message: ReactNode;
};

export const ValidationMessage = (props: ValidationMessageProps) => {
  const { type, message } = props;

  return (
    <View
      style={combineUnstableStyles(
        cssUnstableStyles.wrapper,
        type === 'error' ? cssUnstableStyles.error : cssUnstableStyles.info
      )}
    >
      {type === 'error' && <ErrorIcon style={cssUnstableStyles.leftIcon} color="background" />}
      <Text size="m" style={cssUnstableStyles.text}>
        {message}
      </Text>
    </View>
  );
};
