import { downloadFileSchema } from '@almond/extension-utils';
import { useEvent } from '@almond/utils';
import { v4 } from 'uuid';

import { filterUniqueFiles, useDraftAttachmentContext } from '~/modules/documents';

import { useExtensionMessageListener } from '../hooks';

import type { DownloadFile } from '@almond/extension-utils';

export const ChromeBrowserExtensionUploader = () => {
  const { setAttachments } = useDraftAttachmentContext();

  const processUpload = useEvent(async (elationData: DownloadFile) => {
    const id = v4();

    setAttachments(f =>
      filterUniqueFiles([
        ...f,
        {
          id,
          elationDocumentId: elationData.documentId,
          isHealthRecord: true,
        },
      ])
    );
  });

  useExtensionMessageListener(downloadFileSchema, processUpload);

  return null;
};
