import { useEvent } from '@almond/utils';
import { useSWRConfig } from 'swr';

import { useAlmondApiMutation, useAlmondApiQuery } from '~/modules/api';

import { useProcessDocuments } from './useProcessDocuments';

import type { DocumentCategoriesEnum, DocumentCreateIn, DocumentOut, HealthRecordTypesEnum } from '@almond/api-types';
import type { AlmondApiQueryOptions } from '~/modules/api';

const DOCUMENTS_PATH = (patientUuid = '{patient_uuid}') =>
  `/patients/${patientUuid as '{patient_uuid}'}/documents/` as const;

/**
 * Get and mutate documents for a patient
 * @param patientUuid The UUID of the patient to fetch documents for
 * @param category "Health Records" or "Shared Media"
 * @param options Almond API options
 */
export function useDocuments(
  patientUuid: string | null | undefined,
  category?: DocumentCategoriesEnum,
  options?: Omit<AlmondApiQueryOptions<'/patients/{patient_uuid}/documents/', 'get'>, 'query'>
) {
  // FETCH DOCUMENTS
  const { mutate: globalMutate } = useSWRConfig();
  const documentsPath = patientUuid && category ? DOCUMENTS_PATH(patientUuid) : null;
  const {
    data,
    isLoading: isLoadingData,
    error: dataError,
    mutate: thisMutate,
  } = useAlmondApiQuery(documentsPath, {
    ...(options || {}),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    query: { category: category! }, // First argument is null if category is falsy, so this is safe
    revalidateIfStale: false,
  });
  const { documents, usersError, isLoadingUsers } = useProcessDocuments(data?.documents ?? []);

  // CREATE A NEW DOCUMENT
  const { trigger: triggerAdd } = useAlmondApiMutation('post', patientUuid ? DOCUMENTS_PATH(patientUuid) : null);
  const addToDocuments = useEvent(async (attachments: DocumentCreateIn[]) => {
    if (!patientUuid) throw new Error('Patient UUID is not defined');

    if (!attachments.length) return [];

    try {
      const result = await Promise.all(attachments.map(attachment => triggerAdd(attachment)));
      // Can only upload 1 category at a time, so the first item's category is guaranteed to be the same as all others
      const thisCategory = result[0].category;

      // Add new item(s) to cache so new document is shown in media immediately
      globalMutate(
        key =>
          Array.isArray(key) &&
          typeof key[0] === 'string' &&
          key[0].startsWith(`${DOCUMENTS_PATH(patientUuid)}?category=${thisCategory}`),
        d => ({ documents: [...result, ...(d?.documents ?? [])] }),
        { revalidate: false }
      );

      return result;
    } catch (e) {
      throw new Error('Failed to upload document. Please try again.');
    }
  });

  // Remove an item from this document category's cache. Useful when deleting a document or
  // moving it into a different category
  const removeFromCache = async (document: Pick<DocumentOut, 'uuid'>) => {
    // Remove item from cache so it disappears from media immediately
    return thisMutate(
      oldData => {
        if (!oldData) return;

        return { documents: oldData.documents.filter(({ uuid }) => uuid !== document.uuid) };
      },
      { revalidate: false }
    );
  };

  // DELETE A DOCUMENT
  const {
    trigger: triggerDelete,
    isMutating: isDeleting,
    error: documentDeleteError,
  } = useAlmondApiMutation('delete', `${DOCUMENTS_PATH()}{document_uuid}`);
  const deleteDocument = useEvent(async (document: Pick<DocumentOut, 'category' | 'uuid'>) => {
    if (!patientUuid) return;
    if (document.category !== category) {
      throw new Error(`Can't delete a ${document.category} document with a ${category} hook`);
    }

    const result = await triggerDelete({ document_uuid: document.uuid, patient_uuid: patientUuid });

    await removeFromCache(document);

    return result;
  });

  // MOVE A DOCUMENT TO A DIFFERENT CATEGORY
  const { trigger: triggerPut } = useAlmondApiMutation('put', `${DOCUMENTS_PATH()}{document_uuid}`);
  const moveToHealthRecords = useEvent(async (document: DocumentOut, documentType: HealthRecordTypesEnum) => {
    if (document.category !== category) {
      throw new Error(`Can't delete a ${document.category} document with a ${category} hook`);
    }

    if (!patientUuid) return;

    const result = await triggerPut({
      patient_uuid: patientUuid,
      document_uuid: document.uuid,
      documentType,
    });

    // Remove from current cache
    await removeFromCache(result);
    // Add to other cache
    await globalMutate(
      key =>
        Array.isArray(key) &&
        typeof key[0] === 'string' &&
        key[0].startsWith(`${DOCUMENTS_PATH(patientUuid)}?category=${result.category}`),
      d => ({ documents: [result, ...(d?.documents ?? [])] }),
      { revalidate: false }
    );
  });

  const triggerRevalidate = useEvent(() => {
    return globalMutate(
      key =>
        Array.isArray(key) &&
        typeof key[0] === 'string' &&
        patientUuid &&
        key[0].startsWith(`${DOCUMENTS_PATH(patientUuid)}${category ? `?category=${category}` : ''}`),
      undefined,
      { revalidate: true }
    );
  });

  return {
    data: documents,
    loadError: dataError || usersError,
    triggerRevalidate,
    isLoadingData: isLoadingData || isLoadingUsers,
    isDeleting,
    documentDeleteError,
    addToDocuments,
    deleteDocument: category ? deleteDocument : null,
    moveToHealthRecords: category === 'media' ? moveToHealthRecords : null,
  };
}
