import React, { useMemo } from 'react';

import { PulseProvider, SkeletonLoader, Text, useTheme } from '@almond/ui';
import { Link } from 'expo-router';

import { LiveTodoItem, useGetTodos, useMarkTodos } from '~/modules/todos';
import { useCurrentUser } from '~/modules/user';

import { SectionWithItems } from '../SectionWithItems';

import { themedStyles } from './styles';

export const TodoWidget: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();

  const { active, modifyCache, isLoading } = useGetTodos({ patientUuid: currentUser?.patientUuid, skipArchived: true });
  const { markItem: markItemComplete } = useMarkTodos(currentUser?.patientUuid, 'complete');

  const title = (() => {
    if (isLoading) return <SkeletonLoader.RoundedRectangle width={220} height={16} roundness={4} />;

    return 'Upcoming To Dos';
  })();

  const todos = useMemo(
    () =>
      active
        ?.sort((a, b) => {
          if (!a.dueDate && !b.dueDate) return 0;
          if (!a.dueDate) return 1;
          if (!b.dueDate) return -1;

          return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        })
        .slice(0, 5),
    [active]
  );

  if (!isLoading && !todos.length) {
    return null;
  }

  return (
    <PulseProvider duration={1500}>
      <SectionWithItems
        title={title}
        footer={
          <Link href="/(patient)/todos" style={styles.link}>
            <Text size="s">View To Do list</Text>
          </Link>
        }
      >
        {todos.map(todo => (
          <LiveTodoItem
            key={todo.uuid}
            item={todo}
            displayLite
            onComplete={async isCompleted => {
              const result = await markItemComplete(todo, isCompleted);

              if (result) {
                modifyCache('archiveStatus', result);
              }
            }}
          />
        ))}
      </SectionWithItems>
    </PulseProvider>
  );
};
