import { useCallback } from 'react';

import { useFocusEffect } from 'expo-router';

import { useChromeBrowserExtensionCarePlanVisitNoteContext } from '~/modules/extension';
import { ErrorContainer } from '~/modules/logging';
import { AdminTodoPage, BulkTodoPage, TodoPageWrapper } from '~/modules/todos';

export { ErrorContainer as ErrorBoundary };

export default () => {
  const { showBulkUi, clearPendingState } = useChromeBrowserExtensionCarePlanVisitNoteContext();

  // When navigating away from the /todos page, clear any pending bulk state,
  // so it's gone if a user comes back to this page without pressing the
  // "Send to Artemis" button again
  useFocusEffect(
    useCallback(() => {
      return () => {
        clearPendingState();
      };
    }, [clearPendingState])
  );

  return (
    <TodoPageWrapper>
      {showBulkUi ? (
        // key is important - force re-render and re-fetch when receiving a new event from Elation
        <BulkTodoPage />
      ) : (
        <AdminTodoPage />
      )}
    </TodoPageWrapper>
  );
};
