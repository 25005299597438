import { useEffect } from 'react';

import { useDeferred } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import { getChannelPatient } from '~/modules/user';

export const usePreferredNamePromise = () => {
  const { channel } = useChatContext();
  const channelPatient = getChannelPatient(channel);
  const preferredNameDeferred = useDeferred<string>();

  useEffect(() => {
    if (channelPatient) {
      preferredNameDeferred.resolve(String(channelPatient.preferred_first_name || channelPatient.first_name));
    }
  }, [channelPatient, preferredNameDeferred]);

  // Get a promise that resolves to the patient's preferred name
  return preferredNameDeferred.promise;
};
